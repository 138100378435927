import React, { useState } from 'react'
import { MenuSelect, Panel, connectRange } from 'react-instantsearch-dom'
import { Link } from "gatsby"
import Refresh from '../../images/Refresh.svg'
import './SearchFilter.scss'

const PriceRangeInput = ({ currentRefinement, min, max, precision, refine }) => {
    const [priceMin, setPriceMin] = useState(currentRefinement.min || 0);
    const [priceMax, setPriceMax] = useState(currentRefinement.max || 0);
    return (
        <div className='ais-RangeInput'>
            <form className='ais-RangeInput-form'>
                <input
                    type="number"
                    min={min}
                    max={max}
                    step={1 / Math.pow(10, precision)}
                    placeholder={min || ''}
                    value={priceMin || ''}
                    className="ais-RangeInput-input ais-RangeInput-input--min"
                    onChange={event => {
                        let minVal = parseInt(event.currentTarget.value);
                        setPriceMin(minVal);

                        if (minVal >= min && (minVal <= priceMax || priceMax === 0) && minVal <= max) {
                            refine({
                                ...currentRefinement,
                                min: event.currentTarget.value,
                            })
                        }
                        if (event.currentTarget.value == "") {
                            refine({
                                ...currentRefinement,
                                min: '',
                            })
                        }
                    }}
                />
                <span>TO</span>
                <input
                    type="number"
                    min={min}
                    max={max}
                    step={1 / Math.pow(10, precision)}
                    placeholder={max || ''}
                    value={priceMax || ''}
                    className="ais-RangeInput-input ais-RangeInput-input--min"
                    onChange={event => {
                        let maxVal = parseInt(event.currentTarget.value);
                        setPriceMax(maxVal)
                        if (maxVal >= priceMin && (maxVal <= max || priceMin === 0) && maxVal >= min) {
                            refine({
                                ...currentRefinement,
                                max: event.currentTarget.value,
                            })
                        }
                        if (event.currentTarget.value == "") {
                            refine({
                                ...currentRefinement,
                                max: '',
                            })
                        }
                    }}
                />
            </form>
        </div>
    )
};

const CustomRangeInput = connectRange(PriceRangeInput);

const SearchFilter = (props) => {
    return (
        <div className="search-filter-container">
            <details class="accordion">
                <summary class="summary"><p className='more-filter-btn'>REFINE SEARCH</p></summary>
                <Panel className="inner-search-box">
                    <div className='select-options-container'>
                        <div className="select-menu-container">
                            <p>Property Type</p>
                            <MenuSelect attribute="propertyCategory" />
                        </div>
                        <div className="select-menu-container">
                            <p>Type</p>
                            <MenuSelect attribute="propertyType" />
                        </div>
                        <div className="price-range-container">
                            <p>Price Range</p>
                            <CustomRangeInput attribute="propertyPrice" />
                        </div>
                        <div className="select-menu-container">
                            <p>Bedrooms</p>
                            <MenuSelect attribute="numberOfBedrooms" />
                        </div>
                        <div className="select-menu-container">
                            <p>Bathrooms</p>
                            <MenuSelect attribute="numberOfBathrooms" />
                        </div>
                        <div className="select-menu-container">
                            <p>Neighborhood</p>
                            <MenuSelect attribute="neighborhood.title" />
                        </div>
                        <div className="reset">
                        <Link to="/properties" className='primary-btn'> Reset </Link>
                    </div>
                    </div>
                </Panel>
            </details>
            <Panel className="desktop-section inner-search-box">
                <div className='select-options-container'>
                    <div className="select-menu-container property-type">
                        <p>Property Type</p>
                        <MenuSelect attribute="propertyCategory" />
                    </div>
                    <div className="select-menu-container type">
                        <p>Type</p>
                        <MenuSelect attribute="propertyType" />
                    </div>
                    <div className="price-range-container">
                        <p>Price Range</p>
                        <CustomRangeInput attribute="propertyPrice" />
                    </div>
                    <div className="select-menu-container bedroom">
                        <p>Bedrooms</p>
                        <MenuSelect attribute="numberOfBedrooms" />
                    </div>
                    <div className="select-menu-container bathroom">
                        <p>Bathrooms</p>
                        <MenuSelect attribute="numberOfBathrooms" />
                    </div>
                    <div className="select-menu-container">
                        <p>Neighborhood</p>
                        <MenuSelect attribute="neighborhood.title" />
                    </div>
                    <div className="reset">
                        <Link to="/properties"> <img src={Refresh} alt="refresh" /> </Link>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default SearchFilter